.wrapper {
  &.isPage {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: #fffc;
    z-index: 10;
  }
}
