@import 'src/assets/scss/colors.scss';

.wrapper {
  :global {
    .custom-control-label {
      &:before,
      &:after {
        width: 20px;
        height: 20px;
      }
      &:before {
        border: 1px solid #747474;
      }
      > span {
        color: $black-2;
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
  &.variant_primary {
    :global {
      .custom-control-input ~ .custom-control-label::before {
        border: 1px solid #a3a3a3 !important;
        box-shadow: none !important;
      }
      .custom-control-input:checked ~ .custom-control-label::before,
      .custom-control-input:not(:disabled):active
        ~ .custom-control-label::before {
        background: $primary !important;
        border-color: $primary !important;
        box-shadow: none !important;
      }
      .custom-control-input:disabled,
      .custom-control-input:disabled ~ .custom-control-label::before {
        background: #a3a3a3 !important;
        border-color: #a3a3a3 !important;
      }
      .custom-control-input:disabled,
      .custom-control-input:disabled ~ .custom-control-label::before,
      .custom-control-input:disabled ~ .custom-control-label::after {
        cursor: not-allowed;
      }
    }
  }
  &.fontSize_sm {
    :global {
      .custom-control-label {
        > span {
          font-size: 12px;
        }
      }
    }
  }
  &.fontSize_md {
    :global {
      .custom-control-label {
        > span {
          font-size: 14px;
        }
      }
    }
  }
}
