.wrapper {
  height: 40px;
  width: 178px;
  display: flex;
  > svg {
    height: 40px;
    width: 178px;
    position: relative;
    left: -26px;
  }
  &.isMini {
    > svg {
      > path {
        &:last-child {
          display: none;
        }
      }
    }
  }
  &.variant_authentication {
    width: 270px;
    height: 65px;
    > svg {
      width: 270px;
      height: 65px;
      left: 0;
    }
  }
}
