@import 'src/assets/scss/colors.scss';
@import 'src/assets/scss/mixins.scss';

:global {
  .sidenav-toggler {
    padding: 0;
    width: 28px;
    height: 28px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    background: white;
    position: fixed;
    top: 18px;
    border-radius: 50%;
    left: calc(260px - 14px);
    box-shadow: 2px 2px 8px 0px #0000001a;
    z-index: 300;
  }
  .g-sidenav-hidden:not(.g-sidenav-show) {
    .nav-label-top {
      padding: 0;
      margin-bottom: 8px;
      margin-top: 16px;
      > span {
        display: none;
      }
      &:before {
        left: 8px;
        right: 8px;
      }
    }
    .sidenav-toggler {
      left: calc(90px - 14px);
    }
    .navbar-vertical.navbar-expand-xs {
      max-width: 90px;
      min-width: 90px;
      overflow: initial;
      .navbar-nav {
        > .nav-item {
          min-width: 92px;
          padding-right: 3px;
        }
        .nav-item {
          position: relative;
          .nav-link {
            margin-left: 8px !important;
            margin-right: 8px !important;
            display: flex;
            flex-direction: column;
            gap: 8px;
            padding: 10px 8px !important;
            border-radius: 4px;
            .nav-link-text {
              margin-left: 0;
              margin-right: 0;
              opacity: 1;
              font-size: 10px;
              line-height: 16px;
              white-space: pre-wrap;
              text-align: center;
              & + span {
                display: none;
              }
            }
            & + div {
              position: absolute;
              left: 90px;
              display: none;
              top: 0;
              border-radius: 8px;
              box-shadow: 0px 2px 6px 0px #a7ada84d;
              // padding-left: 4px;
              .nav {
                background: white;
                border-radius: 8px;
                margin-left: 4px;
                box-shadow: 0px 2px 6px 0px #a7ada84d;
                overflow: hidden;
                overflow-y: auto;
                max-height: 380px;
                display: block;
                @include beauty-scroll();
                .nav-item {
                  margin: 0 !important;
                  .nav-link {
                    color: $black-1;
                    font-size: 12px;
                    margin: 0 !important;
                    padding: 12px !important;
                    min-width: 120px;
                    justify-content: flex-start;
                    align-items: flex-start;
                    border-radius: 0;
                    .sidenav-normal {
                      display: block !important;
                      white-space: nowrap;
                    }
                  }
                  &:nth-child(1) {
                    .nav-link {
                      border-radius: 8px 8px 0 0;
                    }
                  }
                  &:last-child {
                    .nav-link {
                      border-radius: 0 0 8px 8px;
                    }
                  }
                }
              }
            }
            &.nav-link-parent {
              .nav-icon-active {
                display: none !important;
              }
            }
          }
          .nav-link-parent.nav-link-parent-active {
            background: $primary !important;
            .nav-icon {
              display: none !important;
            }
            .nav-icon-active {
              display: block !important;
            }
            .nav-link-text {
              color: white !important;
            }
          }
          &:hover {
            .nav-link {
              & + div {
                display: block;
              }
            }
            .nav-link-parent:not(.nav-link-parent-active) {
              background: $primary-0-5 !important;
            }
          }
        }
        > .nav-item {
          .nav {
            > .nav-item {
              > .nav-link {
                padding-left: 12px !important;
                margin-left: 24px !important;
                .nav-link-text {
                  display: none;
                }
              }
            }
          }
        }
      }
      .navbar-brand {
        display: block;
        padding-left: 24px;
        padding-right: 24px;
        .navbar-brand-text {
          display: none;
        }
        .navbar-brand-text-mobile {
          display: block;
        }
        &:after {
          border-width: 2px;
          height: 2px;
          left: 8px;
          right: 8px;
        }
      }
    }
  }
  .navbar-vertical.navbar-expand-xs {
    border-color: #e5e5e5;
    @include beauty-scroll();
    .sidenav-header {
      // position: relative;
      height: 64px;
      position: sticky;
      height: 64px;
      top: 0;
      background: $white;
      z-index: 2;
    }
    .navbar-brand {
      padding: 12px 16px;
      .navbar-brand-text-mobile {
        display: none;
      }
      > span {
        font-size: 38px;
        color: black;
        font-weight: 400;
        line-height: 48px;
      }
    }
  }
  .g-sidenav-pinned.g-sidenav-show {
    .navbar-vertical.navbar-expand-xs {
      .sidenav-toggler {
        > svg {
          transform: rotate(-180deg);
        }
      }
    }
  }
}
.sidebar {
  &:global(.navbar-vertical.navbar-expand-xs) {
    transform: none !important;
    border-color: rgba($black, 0.1);
    :global {
      .navbar-nav {
        .nav-item {
          margin-top: 8px;
          .nav-link {
            font-size: 14px;
            line-height: 20px;
            color: $black-1;
            display: flex;
            align-items: center;
            padding-left: 12px;
            padding-right: 12px;
            margin-left: 16px;
            margin-right: 16px;
            font-weight: 400;
            padding-top: 0.6rem;
            padding-bottom: 0.6rem;
            .nav-icon-active {
              display: none;
            }
            &.active {
              // font-weight: 600;
              background: transparent;
              margin-left: 16px;
              margin-right: 16px;
              padding-left: 12px;
              padding-right: 12px;
              border-radius: 4px;
            }
            &:hover {
              padding-left: 12px;
              padding-right: 12px;
              background: $primary;
              color: white;
              margin-left: 16px;
              margin-right: 16px;
              border-radius: 4px;
              .nav-icon {
                display: none;
              }
            }
            &:not(.nav-link-parent) {
              &:hover {
                .nav-icon-active {
                  display: inline-block;
                }
              }
            }
            .nav-link-text {
              margin-left: 12px;
            }
          }
        }
        > .nav-item {
          .nav {
            > .nav-item {
              > .nav-link {
                padding-top: 0.5rem;
                padding-bottom: 0.5rem;
              }
            }
          }
        }
      }
    }
  }
  &:global(.sidenav) {
    z-index: 200;
  }
}
.selectLocationWrapper {
  display: flex;
  margin-bottom: 16px;
  > div {
    width: 100%;
  }
}
.navLinkParent {
  background: transparent !important;
  color: $black-1 !important;
  padding-right: 0 !important;
  &:after {
    color: $black-1 !important;
  }
  :global {
    .nav-icon {
      display: flex !important;
    }
  }
}
.navLink {
  :global {
    .sidenav-normal {
      white-space: pre-wrap;
    }
    .nav-icon,
    .nav-icon-active {
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
  &:after {
    display: none !important;
  }
  &.navLinkActive {
    background: $primary !important;
    color: white !important;
    :global {
      .nav-icon-active {
        display: block !important;
      }
      .nav-icon {
        display: none !important;
      }
      .nav-link-text,
      .sidenav-normal {
        font-weight: 600;
      }
    }
  }
  &.navLinkParentActive {
    font-weight: 600 !important;
  }
  &.isSub {
    :global {
      .sidenav-normal {
        padding-left: 0 !important;
      }
    }
    &:not(.navLinkActive) {
      &:hover {
        background: $primary-0-5 !important;
        color: $black-1 !important;
      }
    }
  }
  &[aria-expanded='true'] {
    .collapseArrow {
      transform: rotate(90deg);
    }
  }
}
.navItem {
  &.borderTop {
    position: relative;
    padding-top: 12px;
    margin-top: 12px;
    &:before {
      content: ' ';
      height: 2px;
      border-top: 2px solid rgba($primary, 0.1);
      position: absolute;
      left: 16px;
      right: 16px;
      top: 0;
    }
  }
}
.collapseArrow {
  margin-left: auto;
  width: 20px;
  height: 20px;
  &:before {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: 'Font Awesome 5 Free';
    font-weight: 700;
    content: '\f105';
    margin-left: auto;
    transition: all 0.15s ease;
    color: $black-1;
    width: 20px;
    height: 20px;
  }
}
.brandWrapper {
  width: 100%;
  position: relative;
  &:after {
    content: ' ';
    display: block;
    height: 1px;
    border-top: 1px solid rgba($primary, 0.1);
    position: absolute;
    left: 16px;
    right: 16px;
    bottom: 0;
  }
}
.navbarInner {
  &:global(.navbar-inner) {
    :global {
      .collapse.show.navbar-collapse {
        padding-top: 8px;
        &:before {
          display: none;
        }
      }
    }
    > div {
      > ul {
        padding-bottom: 24px;
      }
    }
  }
}
.navLabelTop {
  text-transform: uppercase;
  font-size: 10px;
  line-height: 12px;
  font-weight: 500;
  color: $primary;
  &.borderTop {
    position: relative;
    margin-top: 12px;
    padding-top: 12px;
    padding-left: 28px;
    &:before {
      content: ' ';
      height: 2px;
      border-top: 2px solid rgba($primary, 0.1);
      position: absolute;
      left: 16px;
      right: 16px;
      top: 0;
    }
  }
}
