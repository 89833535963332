@import 'src/assets/scss/colors.scss';

.wrapper {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: $primary;
  border: 1px solid $primary;
  color: white;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  padding: 4px 12px;
  border-radius: 24px;
  &.variant_green {
    background: $success-0-5;
    color: $success-3;
    border: 1px solid $success-3;
  }
  &.variant_red {
    background: $error-0-5;
    color: $error-3;
    border: 1px solid $error-3;
  }
  &.variant_orange {
    background: $warning-0-5;
    color: $warning-3;
    border: 1px solid $warning-3;
  }
  &.weight_lg {
    font-weight: 600;
  }
}

.statusTag {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  border-radius: 24px;
  border: 1px solid $black-3;
  background: transparent;
  color: $black-3;
  &.color_green {
    color: #45b942;
    border-color: #45b942;
    background: #45b9421a;
  }
  &.color_red {
    color: #ff0000d9;
    border-color: #ff0000d9;
    background: #ff000003;
  }
}
