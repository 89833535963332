@import 'src/assets/scss/colors.scss';

.wrapper {
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  position: relative;
  background: white;
}
.rightBg {
  width: 520px;
  // background-color: $primary-9;
  background: url('~/src/assets/icons/Auth/AuthBg.svg');
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  > svg {
    width: 100%;
    height: 100%;
  }
}
.content {
  flex: 1;
  width: 100%;
  padding-right: 520px;
}
