@import 'src/assets/scss/colors.scss';

:global {
  .input-group:not(.has-validation) > .form-control:not(:last-child) {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
}

.wrapper {
  border: 1px solid rgba($black, 0.2);
  font-size: 16px;
  line-height: 24px;
  color: $black;
  &::placeholder {
    color: rgba($black, 0.4) !important;
  }
  &.radius_md {
    border-radius: 4px !important;
  }
  &.variant_primary {
    &:focus {
      border-color: rgba($black, 0.8);
      &.activeShadow {
        box-shadow: 0px 0px 0px 2px #09624233;
      }
    }
  }
  &.fontSize_sm {
    font-size: 14px;
    line-height: 20px;
  }
  &:global(.is-invalid) {
    border-color: rgba($error-5, 0.8) !important;
  }
}
.error {
  margin-top: 8px;
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
  > svg {
    position: relative;
    top: -2px;
    margin-right: 6px;
  }
  &:global(.invalid-feedback) {
    color: rgba($error-5, 0.8);
  }
}
.inputWrapper {
  display: flex;
  flex-direction: column;
  input,
  textarea {
    min-width: 100% !important;
    max-width: 100% !important;
    border: 1px solid #00000033;
    font-size: 14px;
    line-height: 17px;
    color: $black !important;
  }
  textarea {
    min-height: 154px;
  }
  input {
    height: 44px;
  }
}
.label {
  font-size: 14px;
  line-height: 20px;
  color: $black-1;
  font-weight: 500;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
  gap: 4px;
}
.searchInputWrapper {
  :global {
    .input-group-text {
      background: #f9f9f9;
      border: 1px solid #e4e4e4;
      border-right: 0;
    }
    .input-group-alternative {
      box-shadow: none;
    }
  }
  input {
    background: #f9f9f9 !important;
    border: 1px solid #e4e4e4 !important;
    border-left: 0 !important;
  }
  &.size_medium {
    input {
      height: 36px;
    }
  }
  &.isIconRight {
    :global {
      .input-group-text {
        border: 1px solid #e4e4e4;
        border-left: 0;
        border-radius: 0 4px 4px 0;
      }
    }
    input {
      background: #f9f9f9 !important;
      border: 1px solid #e4e4e4 !important;
      border-right: 0 !important;
      border-radius: 4px 0 0 4px !important;
    }
  }
  &.isLight {
    :global {
      .input-group-text {
        background: $white;
      }
    }
    input {
      background: $white !important;
    }
  }
}
