@import 'src/assets/scss/colors.scss';

.wrapper {
  display: flex;
  gap: 80px;
  align-items: center;
}
.item {
  display: flex;
  flex-direction: column;
  gap: 2px;
  font-size: 14px;
  line-height: 20px;
  > span {
    &:nth-child(1) {
      font-weight: 600;
    }
  }
}
.actions {
  display: flex;
  margin-left: auto;
  > span {
    .iconWrapper {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border: 1px solid #e4e4e4;
      border-right: 0;
    }
    &:first-child {
      .iconWrapper {
        border-radius: 8px 0 0 8px;
      }
    }
    &:last-child {
      .iconWrapper {
        border-radius: 0 8px 8px 0;
        border-right: 1px solid #e4e4e4;
      }
    }
    &:hover {
      .iconWrapper {
        background: $primary-0-5;
      }
    }
    svg {
      path {
        stroke: $primary-button;
        stroke-width: 2px;
      }
    }
  }
}
