@import 'src/assets/scss/colors.scss';

.navbarTopWrapper {
  &:global(.navbar-top.border-bottom) {
    background: white !important;
    height: 64px;
    color: $black-1;
    border-bottom: 1px solid #e5e5e5;
    position: sticky;
    top: 0;
    z-index: 20;
  }
  :global {
    .input-group-alternative.input-group-merge.input-group {
      border-radius: 4px !important;
      box-shadow: none !important;
      border: 1px solid #e4e4e4 !important;
      background: #f9f9f9 !important;
      input {
        width: 340px;
        color: $black-3;
        &::placeholder {
          color: $black-3 !important;
        }
      }
    }
  }
}
.userProfileText {
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  > span {
    font-size: 14px;
    font-weight: 400;
    &:nth-child(1) {
      color: $primary;
      font-weight: 500;
      line-height: 17px;
    }
    &:nth-child(2) {
      font-size: 12px;
      line-height: 15px;
      color: #9d9d9d;
    }
  }
}
.selectLocationWrapper {
  width: 400px;
}
