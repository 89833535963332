.childContent {
  padding: 8px 24px;
  min-height: calc(100vh - 64px);
  background: #f9f9f9;
  display: flex;
  flex-direction: column;
  position: relative;
}
.backdrop {
  z-index: 101 !important;
}
