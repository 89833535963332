@import 'src/assets/scss/colors.scss';

:global {
  .tooltip {
    .arrow {
      display: none !important;
    }
    .tooltip-inner {
      background: #fafafa;
      border: 1px solid #d7d7d7;
      border-radius: 8px;
      color: black;
      padding: 12px;
      font-size: 16px;
      line-height: 20px;
      font-weight: 400;
      text-align: left;
      max-width: 500px;
    }
    &.tooltip-overlay-dark {
      .tooltip-inner {
        background: $black;
        box-shadow: 0px 2px 8px 0px rgba($black, 0.2);
        color: $white;
        font-size: 14px;
        line-height: 17px;
      }
    }
    &.tooltip-has-arrow {
      .arrow {
        display: block !important;
        width: 20px;
        top: -6px !important;
        height: 15px;
        &:before {
          border-width: 0 10px 10px !important;
          width: 20px;
          height: 15px;
        }
      }
    }
  }
}
