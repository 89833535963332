@import 'src/assets/scss/mixins.scss';
@import 'src/assets/scss/colors.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-radius: 6px;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  > span {
    font-weight: 600;
  }
  > div {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}
.content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.emergencyContact {
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  background: white;
  border: 1px solid #e4e4e4;
  padding: 16px;
  gap: 12px;
  box-shadow: 0px 1px 2px 0px #1018280a;
  .contactHeader {
    line-height: 24px;
    font-weight: 600;
    display: flex;
    gap: 8px;
    font-size: 16px;
    align-items: center;
  }
  .contactContent {
    display: flex;
    flex-direction: column;
    gap: 12px;
    font-size: 14px;
    line-height: 20px;
    b {
      font-weight: 600;
    }
    > span {
      display: flex;
      gap: 4px;
    }
    .line_2 {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 8px;
    }
  }
  .item {
    display: flex;
    flex-direction: column;
    > span {
      &:nth-child(1) {
        font-weight: 600;
      }
    }
  }
  .contactAction {
    display: flex;
    gap: 12px;
    align-items: center;
    margin-left: auto;
  }
}
.moreText {
  @include more-text(1);
}
.link {
  color: $black;
  text-decoration: underline !important;
}
.contacts {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}
.emergencyPlan {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  line-height: 27px;
  .planHeader {
    padding: 16px;
    line-height: 19px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    padding: 8px 16px;
    background: $neutrals-0-5;
    > div {
      :global {
        .btn.btn-secondary {
          background: $white !important;
        }
      }
    }
  }
  .planContent {
    padding: 8px 0;
    > ul {
      list-style: none;
      display: flex;
      flex-direction: column;
      gap: 8px;
      padding: 0;
      margin: 0;
    }
  }
}
.planItem {
  display: flex;
  border: 1px solid $primary-0-5;
  padding-bottom: 12px;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  padding: 12px 16px;
  font-size: 14px;
  line-height: 17px;
  cursor: pointer;
  &:hover {
    background: #f4f8f6;
    border-color: $primary-button;
    box-shadow: 0px 2px 12px 0px #082a151a;
  }
  .planItemInfo {
    display: flex;
    flex-direction: column;
    gap: 6px;
    flex: 1;
  }
  .planItemDate {
    margin-right: 60px;
  }
  .planItemArrow {
    svg {
      cursor: pointer;
    }
  }
}
.plans {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}
.contactsWrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.emergencyEmptyPlan {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  line-height: 18px;
  padding: 56px 12px;
  color: $black-3;
  > div {
    display: inline-flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    justify-content: center;
    > svg {
      margin-bottom: 4px;
      width: 24px;
      height: 24px;
      path {
        fill: #292d32;
      }
    }
  }
}
