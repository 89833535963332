@import 'src/assets/scss/colors.scss';

.wrapper {
  position: relative;
  :global {
    table.table {
      color: black;
      thead {
        tr {
          th {
            padding: 16px;
            background: #f6f6f6;
            color: black;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 13px;
            line-height: 16px;
            border-color: $gray-2;
          }
        }
      }
      tbody {
        tr {
          td {
            padding: 16px;
            font-size: 14px;
            line-height: 17px;
            border-color: $gray-2;
          }
        }
      }
    }
  }
  &.hoverable {
    :global {
      table.table {
        tbody {
          tr {
            td {
              padding: 16px;
              font-size: 14px;
              line-height: 17px;
              border-bottom: 1px solid $gray-2;
            }
            &:last-child {
              td {
                border-top: 0;
                border-bottom: 1px solid $gray-2;
              }
            }
            &:hover {
              td {
                border-color: $primary-1;
                background: $primary-0-5;
              }
            }
          }
        }
      }
    }
  }
}
.columnHeaderInner,
.tdInner {
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: break-spaces;
  word-break: break-word;
}
.checkbox {
  top: -3px;
}
.actionOverlay {
  :global {
    .popover.show {
      min-width: 80px;
    }
  }
}
.sortIcon {
  cursor: pointer;
  min-width: 16px;
  min-height: 16px;
  &.hasSort {
    path {
      fill: #0866ff;
    }
  }
  &.sortASC {
    transform: rotate(-180deg);
  }
}
.loading {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff80;
  z-index: 10;
  > div {
    display: flex;
  }
}
