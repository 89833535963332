:global {
  .popover.show {
    background: white;
    border: 1px solid #dbdee2;
    border-radius: 4px;
    padding: 12px;
    box-shadow: 0px 4px 8px 0px #00000008;
    .arrow {
      display: none;
    }
  }
}
.wrapper {
  border: 0 !important;
  background: transparent !important;
  box-sizing: none !important;
  outline: none !important;
  padding: 0;
  margin: 0;
}
.items {
  display: flex;
  flex-direction: column;
  gap: 16px;
  > div {
    cursor: pointer;
    &:hover {
      color: gray;
    }
  }
}
