@import 'src/assets/scss/colors.scss';

.card {
  background: transparent !important;
  box-shadow: none !important;
  max-width: 440px;
  padding: 0 20px;
  width: 100%;
  margin: 0 auto;
  font-family: 'Outfit', sans-serif;
}
.button {
  width: 100%;
}
.logo {
  margin: 0 auto;
}
.title {
  margin-bottom: 32px;
  font-size: 48px;
  font-weight: 500;
  color: $black;
  line-height: 60px;
}
.subtitle {
  margin-bottom: 4px;
  margin-top: 40px;
  font-size: 28px;
  font-weight: 700;
  line-height: 42px;
  color: $black;
}
.subtitle1 {
  margin: 0;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  color: $black;
}
.description {
  font-size: 16px;
  color: $black;
  font-weight: 400;
  margin-bottom: 11px;
}
.formFields {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.rememberWrapper {
  a {
    color: $primary-button;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-decoration: underline;
    &:hover {
      text-decoration: underline;
    }
  }
}
.cardBody {
  margin: 20px 0 0 0;
  padding: 0;
}
.form {
  font-family: 'Inter', sans-serif;
  label {
    font-weight: 600;
  }
  :global {
    .custom-checkbox {
      .custom-control-label {
        > span {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          top: 4px;
        }
      }
    }
  }
}
.requestAccess {
  display: flex;
  justify-content: center;
  margin-top: 32px;
  > span {
    display: flex;
    align-items: center;
    gap: 16px;
    color: black;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    a {
      text-decoration: underline;
      font-weight: 600;
      color: $primary-button;
    }
  }
}
.alert {
  background: #f3f3f3;
  border-radius: 4px;
  padding: 16px;
  width: 100%;
  font-family: 'Inter', sans-serif;
  > span {
    font-size: 16px;
    font-weight: 400;
    color: black;
    line-height: 19px;
  }
}
.text {
  font-size: 16px;
  font-weight: 400;
  color: black;
  line-height: 19px;
  text-align: center;
  width: 100%;
  margin-top: 32px;
}
.buttonsWrapper {
  margin-top: 36px;
}
