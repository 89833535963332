.wrapper {
  padding-bottom: 24px;
}
.regions {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
.pageTitleWrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}
.overviewWrapper {
  display: flex;
  gap: 20px;
  > div {
    &:nth-child(1) {
      flex: 1;
    }
    &:nth-child(2) {
      min-width: 370px;
      max-width: 370px;
    }
    &:nth-child(3) {
      min-width: 370px;
      max-width: 370px;
    }
  }
}
.locations {
  display: flex;
  flex-direction: column;
  gap: 12px;
  border: 1px solid #e4e4e4;
  border-radius: 6px;
  background: white;
  .locationHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    > span {
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      color: black;
    }
    > div {
      display: flex;
      align-items: center;
      gap: 16px;
      > div {
        &:nth-child(1) {
          width: 232px;
        }
        &:nth-child(2) {
          width: 118px;
        }
        &:nth-child(3) {
        }
      }
    }
  }
}
.locationColInner {
  display: flex;
  flex-direction: column;
  gap: 4px;
  > span {
    &:nth-child(1) {
      font-weight: 500;
    }
    &:nth-child(2) {
      font-size: 12px;
      line-height: 14px;
    }
  }
}
.scoreColInner {
  display: flex;
  align-items: center;
  gap: 6px;
}
.map {
  margin: 0 -16px -16px -16px;
  width: calc(100% + 32px);
}
.chart {
  canvas {
    width: 260px !important;
    height: 260px !important;
    margin: 0 auto;
  }
}
