@import 'src/assets/scss/colors.scss';

.placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 1px dashed rgba($black, 0.2);
  padding: 16px;
  border-radius: 4px;
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 8px;
    > span {
      &:nth-child(1) {
        margin-bottom: 8px;
      }
      &:nth-child(2) {
        font-size: 14px;
        line-height: 20px;
        color: $black;
        font-weight: 600;
      }
      &:nth-child(3) {
        font-size: 12px;
        line-height: 18px;
        color: rgba($black, 0.3);
      }
    }
  }
}
.placeholderButton {
  margin-top: 8px;
  font-size: 14px;
  color: $primary-button;
  font-weight: 600;
  text-decoration: underline;
}
.fileItem {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px;
  border: 1px solid rgba($black, 0.2);
  border-radius: 4px;
  justify-content: space-between;
  > div {
    display: flex;
    flex-direction: column;
    gap: 4px;
    > span {
      &:nth-child(1) {
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
        color: $black;
      }
      &:nth-child(2) {
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
        color: rgba($black, 0.2);
      }
    }
  }
}
.contentFilesList {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
