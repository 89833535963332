@import 'src/assets/scss/colors.scss';

.wrapper {
  padding-bottom: 24px;
}
.regions {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
.pageTitleWrapper {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
}
.overviewWrapper {
  display: flex;
  gap: 20px;
  > div {
    &:nth-child(1) {
      flex: 1;
    }
    &:nth-child(2) {
      flex: 1;
    }
  }
}
.score {
  height: 130px;
  width: 226px;
  margin-top: 16px;
}
.scoreRight {
  flex: 1;
  > div {
    &:nth-child(2) {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-top: 8px;
      > div {
        display: flex;
        justify-content: space-between;
        padding-right: 16px;
        &:nth-child(1) {
          display: block;
        }
      }
    }
  }
  > div {
    width: 100%;
  }
}
.map {
  margin: 0 -16px -16px -16px;
  width: calc(100% + 32px);
}
.subTitle {
  display: flex;
  width: 100%;
  gap: 35px;
  > span {
    display: flex;
    &:nth-child(1) {
      max-width: 429px;
      min-width: 429px;
    }
    &:nth-child(2) {
      flex-direction: column;
      gap: 4px;
      > span {
        &:nth-child(1) {
          font-weight: 700;
        }
      }
    }
  }
}
.warningIcon {
  margin-left: 8px;
  svg {
    path {
      fill: $error-3;
    }
  }
}
