@import 'src/assets/scss/colors.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  background: white;
  border: 1px solid #d8d8d8;
  border-radius: 6px;
  padding: 16px;
  color: black;
  gap: 16px;
  box-shadow: 0px 2px 4px 0px #0000000a;
  &.content_align_horizontal {
    .content {
      gap: 40px;
    }
  }
  &.content_align_vertical {
    .content {
      flex-direction: column;
      gap: 16px;
      > div {
        width: 100%;
      }
    }
  }
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  > span {
    font-size: 20px;
    font-weight: 600;
    color: $black-1;
    line-height: 24px;
    display: flex;
    align-items: center;
    gap: 12px;
  }
}
.content {
  width: 100%;
  min-height: 186px;
  display: flex;
  flex: 1;
}
.subHeading {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: $black-2;
}
.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.footerLeft,
.footerRight {
  display: flex;
  align-items: center;
  gap: 4px;
}
.footerLeft {
  font-size: 14px;
  line-height: 17px;
  white-space: break-spaces;
  svg {
    width: 16px;
    height: 16px;
  }
}
.footerRight {
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
}
